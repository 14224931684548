import styled from "@emotion/styled"
import {Box, Button, Card, CardContent, CardMedia, Fab, Grid, Typography} from "@material-ui/core"
import {IconType} from "@react-icons/all-files"
import {Theme} from "@emotion/react"
import React from "react"
import {FiEdit} from "@react-icons/all-files/fi/FiEdit"
import defaultBackground from "../../data/images/profile/default-background.jpg"
import {CircularAvatar} from "../../components/plus/CircularAvatar"
import defaultAvatar from "../../data/images/profile/default-avatar.png"
import {TextCenteredGrid} from "../diy/components/Shared"
import {ProfileElementProps} from "./ProfilePage"
import {RetrogamerInfos} from "../../components/plus/RetrogamerInfos"

const ProfileCardAvatar = styled(CardContent)({
  position: "relative",
  textAlign: "center",
  marginTop: "-150px"
})
const ProfileCardMedia = styled(CardMedia)((component: any) => ({
  maxHeight: "30vh"
}))

type ImageEditFabProps = { id: string, multiple?: boolean, icon: IconType, onChange: (f: File[]) => void, className?: string }
const FloatingImageEditFab = (props: ImageEditFabProps) => {
  return (
    <Box position="relative">
      <FloatBox>
        <ImageEditFab {...props} />
      </FloatBox>
    </Box>
  )
}
const ImageEditFab = (props: ImageEditFabProps) => {
  return (
    <div className={props.className}>
      <input
        accept="image/*"
        id={props.id}
        multiple={props.multiple}
        type="file"
        onChange={(event) => props.onChange(Object.values(event.target.files!))}
        css={{display: "none"}}
      />
      <label htmlFor={props.id}>
        <Fab size={"small"} component="span" color="secondary" aria-label="add">
          <props.icon/>
        </Fab>
      </label>
    </div>
  )
}

const FloatBox = styled(Box)({
  position: "absolute",
  top: "10px",
  right: "10px"
})

const ProfileCardInfo = styled(Box)((props: { theme?: Theme }) => ({
  marginBottom: props.theme!.spacings.md,
}))

const AvatarImageEditFab = styled(ImageEditFab)({
  position: "absolute",
  top: "20%",
  right: "42%",
})

export const ProfileHeader = (props: ProfileElementProps) => {
  const {user, isCurrent, changeUserBackground, changeUserAvatar} = props

  if (!user) return null

  return (
    <Card>
      {isCurrent && changeUserBackground &&
      <FloatingImageEditFab
        onChange={changeUserBackground}
        icon={FiEdit}
        id={"backgroundedit"}
      />
      }
      <ProfileCardMedia
        component="img"
        alt={`${user.name} background`}
        image={user.background ? user.background.url : defaultBackground}
        title={`${user.name} background`}
      />
      <ProfileCardAvatar>
        <CircularAvatar
          value={85}
          online={true}
          size={150}
          avatarUrl={user.avatar ? user.avatar.url : defaultAvatar}
        />
        {isCurrent && changeUserAvatar &&
        <AvatarImageEditFab onChange={changeUserAvatar} icon={FiEdit} id={"avatarEdit"}/>
        }
      </ProfileCardAvatar>

      <ProfileCardInfo>
        <Grid container alignItems={"center"} alignContent={"center"}>
          <Grid item xs={5}>
            <RetrogamerInfos retrogamer={user}></RetrogamerInfos>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={"h5"} align={"center"}>{user.name}</Typography>
          </Grid>
          {!isCurrent &&
          <Grid item xs={5} container>
            <TextCenteredGrid item xs={6}>
              <Button size={"large"} variant={"outlined"} color={"secondary"}>Add
                Friend</Button>
            </TextCenteredGrid>
            <TextCenteredGrid item xs={6}>
              <Button size={"large"} variant={"outlined"} color={"primary"}>Send Message</Button>
            </TextCenteredGrid>
          </Grid>
          }
        </Grid>
      </ProfileCardInfo>
    </Card>
  )
}
