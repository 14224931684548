import styled from "@emotion/styled"
import {Box, Button, TextField} from "@material-ui/core"
import React, {ChangeEvent, useState} from "react"
import {useDispatch} from "react-redux"
import {Layout} from "../../components/Layout"
import {SectionContainer, SectionTitle} from "../../components/Shared"
import {login, register} from "./profileSlice"
import {RouteComponentProps} from "@reach/router"

const FormInput = styled(TextField)((props: any) => ({
  marginBottom: props.theme.spacings.md
}))

const LoginForm = () => {
  const [state, setState] = useState({username: "", password: ""})
  const dispatch = useDispatch()
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    setState({...state, [name]: value})
  }
  return (
    <form noValidate>
      <Box>
        <FormInput
          name="username"
          label="Username"
          value={state.username}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <FormInput
          type="password"
          name="password"
          label="Password"
          value={state.password}
          onChange={handleChange}
        />
        <Box>
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={() => dispatch(login(state.username, state.password))}
          >
            Login
          </Button>
        </Box>
      </Box>
    </form>
  )
}

const RegisterForm = () => {
  const [state, setState] = useState({rusername: "", remail: "", rpassword: ""})
  const dispatch = useDispatch()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    setState({...state, [name]: value})
  }

  return (
    <form noValidate>
      <Box>
        <FormInput
          name="remail"
          label="Email"
          value={state.remail}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <FormInput
          name="rusername"
          label="Username"
          value={state.rusername}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <FormInput
          type="password"
          name="rpassword"
          label="Password"
          value={state.rpassword}
          onChange={handleChange}
        />
        <Box>
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={() => dispatch(register(state.remail, state.rusername, state.rpassword))}
          >
            Register
          </Button>
        </Box>
      </Box>
    </form>
  )
}

const CenterSectionContainer = styled(SectionContainer)(() => ({
  textAlign: "center"
}))

const LoginPageI = (props: RouteComponentProps) => {
  return (
    <Layout title={"Recalbox Home"}>
      <CenterSectionContainer>
        <SectionTitle>Sign In</SectionTitle>
        <LoginForm/>
      </CenterSectionContainer>
      {process.env.GATSBY_FULL_FEATURES &&
      <CenterSectionContainer>
        <SectionTitle>Register</SectionTitle>
        <RegisterForm/>
      </CenterSectionContainer>
      }
    </Layout>
  )
}

export const LoginPage = {Page: LoginPageI, getUrl: () => "/plus/login"}
