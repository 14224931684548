import React from "react"
import {RootState} from "../store/store"
import {connect, ConnectedProps} from "react-redux"
import {LoginPage} from "../features/profile/LoginPage"
import {RouteComponentProps} from "@reach/router"
import { BreadCrumbElement} from "./Layout"

export type Page = { Page: React.FunctionComponent<any>, getUrl: (...args: any[]) => string, breadCrumb?: BreadCrumbElement, parentPage?: () => Page}

export type PrivatePage = Page & { minimumRole: string }

const PrivateRoute = (props: Props) => {
  if (props.isLogged && props.user?.roles?.find(r => r === props.page.minimumRole)) {
    return <props.page.Page {...props}/>
  }
  return <LoginPage.Page/>
}


const mapState = (state: RootState) => {
  return {
    isLogged: state.profile.isLogged,
    user: state.profile.user
  }
}

const connector = connect(mapState)
type Props = ConnectedProps<typeof connector> & { page: PrivatePage } & RouteComponentProps;
export const PrivateRoutePageRedux = connector(PrivateRoute)


