import React, {Component} from "react"
import {RootState} from "../store/store"
import {connect, ConnectedProps} from "react-redux"
import {LoginPage} from "../features/profile/LoginPage"
import {RouteComponentProps} from "@reach/router"

export type PrivateRouteComponent = React.ComponentType<RouteComponentProps>

export interface PrivateRouteProps extends RouteComponentProps, Props {
  component: PrivateRouteComponent
}

const PrivateRoute: React.FC<PrivateRouteProps> =
  ({
     component: Component
     , isLogged, ...rest
   }) => {
    if (!isLogged) {
      return <LoginPage.Page/>
    }
    return <Component {...rest} />
  }

const mapState = (state: RootState) => {
  return {isLogged: state.profile.isLogged}
}

const connector = connect(mapState)
type Props = ConnectedProps<typeof connector>;
export const PrivateRouteRedux = connector(PrivateRoute)
