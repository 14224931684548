import React, {useEffect, useState} from "react"
import {connect, ConnectedProps, useDispatch, useSelector} from "react-redux"
import {Layout} from "../../components/Layout"
import {SectionContainer} from "../../components/Shared"
import {RootState} from "../../store/store"
import {changeUserImage} from "./profileSlice"
import {ThunkDispatch} from "redux-thunk"
import {AnyAction} from "redux"
import {ProfileHeader} from "./ProfileHeader"
import {CardContent, Grid} from "@material-ui/core"
import {PaddedCard} from "../../components/GameShared"
import {RecalboxBadge} from "../badges/Badges"
import {findUser, getUserRoles} from "../../api/parseApi"
import {RouteComponentProps} from "@reach/router"
import {notification} from "../notification/notificationSlice"
import {CardTitle} from "../../components/Cards"
import {mapParseToUser, User} from "../../api/plusApi"


const UserProfile = (props: ProfileElementProps) => {
  return (
    <React.Fragment>
      <ProfileHeader {...props}></ProfileHeader>
      <Grid container css={{marginTop: "2rem"}}>
        <Grid item xs={4}>
          <PaddedCard>
            <CardTitle>
              Badges
            </CardTitle>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <RecalboxBadge badge={"twoplayers"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"warrior"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"pipe"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"i18n"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"fourPlayers"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"onOfUs"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"theWinner"}/>
                </Grid>
                <Grid item>
                  <RecalboxBadge badge={"theWinner"} locked/>
                </Grid>
              </Grid>
            </CardContent>
          </PaddedCard>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const MyProfilePage = (props: ProfilePageProps) => {
  const {user, isLogged, changeUserAvatar, changeUserBackground} = props
  if (!isLogged || !user) return null
  return (
    <Layout title={`${user?.name} Profil`}>
      <SectionContainer>
        <UserProfile isCurrent={true} user={user} changeUserAvatar={changeUserAvatar}
                     changeUserBackground={changeUserBackground}/>
      </SectionContainer>
    </Layout>
  )
}

export const UserProfilePage = (props: RouteComponentProps & { userId?: string }) => {
  const {userId} = props
  const [user, setUser] = useState<User | null>(null)
  const dispatch = useDispatch()
  const {isLogged, currentUser} = useSelector((state: RootState) => ({
    isLogged: state.profile.isLogged,
    currentUser: state.profile.user
  }))

  useEffect(() => {
    findUser(userId ?? "").then((parseUser => {
      if (!parseUser) {
        dispatch(notification("Unable to find user", "warning"))
      }else {
        getUserRoles(parseUser).then(roles => {
          setUser(mapParseToUser(parseUser, roles))
        })
      }
    })).catch((e) => {
      dispatch(notification("Unable to find user", "warning"))
    })
  }, [])

  return (
    <Layout title={`${user?.name ?? "Not found"} Profil`}>
      <SectionContainer>
        {user &&
        <UserProfile isCurrent={isLogged && currentUser && currentUser?.name === user?.name || false} user={user}/>
        }
      </SectionContainer>
    </Layout>
  )
}


const mapState = (state: RootState) => {
  return {isLogged: state.profile.isLogged, user: state.profile.user}
}

const mapDispatch = (dispatch: ThunkDispatch<RootState, any, AnyAction>) => {
  return {
    changeUserBackground: (images: File[]) => {
      return dispatch(changeUserImage("background", images[0]))
    },
    changeUserAvatar: (images: File[]) => {
      return dispatch(changeUserImage("avatar", images[0]))
    },
  }
}

const connector = connect(mapState, mapDispatch)
export type ProfilePageProps = ConnectedProps<typeof connector>;
export type ProfileElementProps = { user: User, isCurrent: boolean, changeUserBackground?: (images: File[]) => void, changeUserAvatar?: (images: File[]) => void }
export const MyProfilePageRedux = connector(MyProfilePage)
