import styled from "@emotion/styled"
import {Card, Typography} from "@material-ui/core"
import {Link} from "./i18n/I18nLink"

export const ZoomableCard = styled(Card)({
  height: "100%",
  zIndex: 0,
  ":hover,:focus-within": {
    transition: "all 0.5s ease",
    transform: "scale(1.2)",
    zIndex: 9999,
  }
})

export const CardTitle = styled(Typography)(({theme}) => ({
  fontWeight: "bold",
}))
export const CardSubtitle = styled(Typography)(({theme}) => ({
  fontSize: "1em",
  fontWeight: "inherit",
  color: theme.palette.text.secondary
}))


export const CardTitleAction = styled(Link)({
  float: "right"
})