import {Grid, Typography} from "@material-ui/core"
import React from "react"
import styled from "@emotion/styled"
import {Theme} from "@emotion/react"
import {User} from "../../api/plusApi"

const AccountInfoTopTypo = styled(Typography)({
  fontSize: "1.2em",
  fontWeight: "bold",
  textAlign: "center",
})
const AccountInfoBottomTypo = styled(Typography)((props: { theme: Theme }) => ({
  textAlign: "center",
  fontSize: "1em",

}))

type AccountInfoEntryProps = { top: React.ReactElement | string, bottom: React.ReactElement | string, className?: string, theme?: Theme }

const AccountInfoEntry = (props: AccountInfoEntryProps) => {
  const {top, bottom, className} = props
  return (
    <div className={className}>
      <AccountInfoTopTypo>
        {top}
      </AccountInfoTopTypo>
      <AccountInfoBottomTypo variant={"body2"} color={"textSecondary"}>
        {bottom}
      </AccountInfoBottomTypo>
    </div>
  )
}

const AccountInfoEntryWithBorder = styled(AccountInfoEntry)((props: AccountInfoEntryProps) => ({
  borderRight: `2px solid ${props.theme!.palette.background.highlight}`
}))

export const RetrogamerInfos = (props: { className?: string, retrogamer: User }) => {

  return (
    <Grid container className={props.className}>
      <Grid item xs={3}>
        <AccountInfoEntryWithBorder top={"930"} bottom={"GAMES"}/>
      </Grid>
      <Grid item xs={3}>
        <AccountInfoEntryWithBorder top={"21"} bottom={"FRIENDS"}/>
      </Grid>
      <Grid item xs={3}>
        <AccountInfoEntryWithBorder top={"34"} bottom={"LEVEL"}/>
      </Grid>
      <Grid item xs={3}>
        <AccountInfoEntry top={"123/240"} bottom={"XP"}/>
      </Grid>
    </Grid>
  )
}